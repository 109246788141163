import React, {useState, useEffect} from 'react'
import { NavLink } from "react-router-dom";
import { get, postAPI, putAPI, uploadImageAPI, getDownloadFile } from "../../ApiService";


function Sidebar() {
    const spaax = window.location.pathname;
    const [userPermissions, setUserPermissions] = useState([]);


    const getPermissions = async(page=1) => {
        let data = {page: 1,recPerPage: 500, q: "",func:''}
        let res = await get('/urm/user/get-permissions', data);        
        if(res.status === 1){
            setUserPermissions(res.payload);
        }
    }

    const hasPermissions = (moduleSlug,actionSlug = "") => {
        let permission = false;
        if(userPermissions[0] && userPermissions[0].isGlobal) return true;
        permission = (userPermissions.find((element) => (element.slug == moduleSlug || (
                element.children.find((element1)=>element1.slug== moduleSlug)
            )? true:false
        ) ))?true:false;
        if(permission === true && actionSlug != ""){
            permission = (userPermissions.actions.find((element) => (element.slug == actionSlug) ))?true:false;
        }
        return permission;
    }

    useEffect(() => {
        getPermissions()
    }, [])
  return (
    <div id="layout-wrapper">

<div className ="app-menu navbar-menu">
            {/* <!-- LOGO --> */}
            <div className ="navbar-brand-box">
                {/* <!-- Dark Logo--> */}
                <NavLink to={'/'} className ="logo logo-dark">
                    <span className ="logo-sm">
                        <img src="assets/images/logo-big.png" alt="" style={{ width: "100%", height: "50px" }} />
                    </span>
                    <span className ="logo-lg">
                        <img src="assets/images/logo-big.png" alt="" style={{ width: "100%", height: "50px" }} />
                    </span>
                </NavLink>
                {/* <!-- Light Logo--> */}
                <NavLink to={'/'}className ="logo logo-light">
                    <span className ="logo-sm">
                        <img src="assets/images/logo-big.png" alt="" style={{ width: "100%", height: "50px" }} />
                    </span>
                    <span className ="logo-lg">
                        <img src="assets/images/logo-big.png" alt="" style={{ width: "100%", height: "50px" }} />
                    </span>
                </NavLink>
                <button type="button" className ="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover " >
                    <i className ="ri-record-circle-line"></i>
                </button>
            </div>
            <aside className='scrool_s'>
            <div  className='scrool'>
                

                    
                    <ul className ="navbar-nav mt-3 " >
                       
                       {
                        (hasPermissions('dashboard')) && 
                        <li className ="nav-item">
                            <NavLink to={'/dashboard'} className ="nav-link menu-link" >
                                <i className ="ri-dashboard-2-line"></i> <span data-key="t-dashboards">Dashboard</span>
                            </NavLink>                          
                        </li> 
                       }                       
                        {/* <!-- end Dashboard Menu --> */}
                        {
                        (hasPermissions('project')) && 
                        <li className ="nav-item">
                            <a className ="nav-link menu-link" href="#sidebarApps" data-bs-toggle="collapse" role="button"  aria-controls="sidebarApps" aria-expanded={(spaax==='/single-project' || spaax === '/group-project' || spaax === '/all-project'  ) ? 'true' : 'false'}>
                                <i className ="ri-apps-2-line"></i> <span data-key="t-apps">Project</span>
                            </a>
                            <div className={(spaax==='/single-project' || spaax === '/group-project' || spaax === '/all-project' || spaax === '/recontact' ) ? 'collapse menu-dropdown show' : 'collapse menu-dropdown'} id="sidebarApps">
                                <ul className ="nav nav-sm flex-column">
                                    {(hasPermissions('all-project')) &&                                    
                                    <li className ="nav-item">
                                        <NavLink to={'/all-project'} className ="nav-link" data-key="t-calendar">All Projects </NavLink>
                                    </li>
                                    }
                                    {(hasPermissions('single-project')) &&
                                    <li className ="nav-item">
                                        <NavLink to={'/single-project'} className ="nav-link" data-key="t-calendar">Single Project </NavLink>
                                    </li>
                                    }
                                    {(hasPermissions('group-project')) &&
                                    <li className ="nav-item">
                                        <NavLink to={'/group-project'} className ="nav-link" data-key="t-calendar">Group Project</NavLink>
                                    </li>
                                    }
                                    {(hasPermissions('recontact')) &&
                                    <li className ="nav-item">
                                        <NavLink to={'/recontact'} className ="nav-link" data-key="t-calendar">ReContact</NavLink>
                                    </li> 
                                    }                              
                                </ul>
                            </div>
                        </li>
                        }
                    {
                        (hasPermissions('clients')) && 
                        <li className ="nav-item">
                            {/* <a className ="nav-link menu-link" href="#sidebarLayouts" data-bs-toggle="collapse" role="button" aria-expanded={(spaax==='/client' || spaax === '/add-client'  ) ? 'true' : 'false'} aria-controls="sidebarLayouts">
                                <i className ="ri-layout-3-line"></i> <span data-key="t-layouts">Client</span> 
                            </a>
                            <div className={(spaax==='/client' || spaax === '/add-client'  ) ? 'collapse menu-dropdown show' : 'collapse menu-dropdown'} id="sidebarLayouts">
                                <ul className ="nav nav-sm flex-column">
                                    <li className ="nav-item">
                                        <NavLink to={'/client'}  className ="nav-link" data-key="t-horizontal">All Clients</NavLink>
                                    </li>
                                    <li className ="nav-item">
                                        <NavLink to={'/add-client'}  className ="nav-link" data-key="t-horizontal">Add Client</NavLink>
                                    </li>
                                   
                                </ul>
                            </div> */}
                            <NavLink  to={'/client'} className ={(spaax==='/client' || spaax === '/add-client'  ) ? 'nav-link active' : 'nav-link '} > <i className ="ri-layout-3-line"></i> Clients</NavLink>

                        </li> 
                    }
                        {/* <!-- end Client Menu --> */}

                      
                        {(hasPermissions('suppliers')) && 
                        <li className ="nav-item">
                            {/* <a className ="nav-link menu-link" href="#sidebarAuth" data-bs-toggle="collapse" role="button" aria-expanded={(spaax==='/supplier' || spaax === '/add-supplier'  ) ? 'true' : 'false'} aria-controls="sidebarAuth">
                                <i className ="ri-account-circle-line"></i> <span data-key="t-authentication">Supplier</span>
                            </a>
                            <div className={(spaax==='/supplier' || spaax === '/add-supplier'  ) ? 'collapse menu-dropdown show' : 'collapse menu-dropdown'}  id="sidebarAuth">
                                <ul className ="nav nav-sm flex-column">
                                    <li className ="nav-item">
                                        <NavLink to={'/supplier'} className ="nav-link" >All Suppliers
                                        </NavLink>
                                       
                                    </li>
                                    <li className ="nav-item">
                                        <NavLink to={'/add-supplier'} className ="nav-link" >Add Supplier
                                        </NavLink>
                                       
                                    </li>
                                   
                                </ul>
                            </div> */}
                            <NavLink  to={'/supplier'} className ={(spaax==='/supplier' || spaax === '/add-supplier'  ) ? 'nav-link active' : 'nav-link '} > <i className ="ri-account-circle-line"></i> Suppliers</NavLink>
                                       
                        </li>
                        }

                    {
                        (hasPermissions('library')) && 

                        <li className ="nav-item">
                            <a className ="nav-link menu-link" href="#sidebarAuth2" data-bs-toggle="collapse" role="button" aria-expanded={(spaax==='/question' || spaax === '/question'  ) ? 'true' : 'false'} aria-controls="sidebarAuth2">
                            <i className ="ri-pages-line"></i> <span data-key="t-authentication">Library</span>
                            </a>
                            <div className={(spaax==='/question' || spaax === '/prescreen'  ) ? 'collapse menu-dropdown show' : 'collapse menu-dropdown'} id="sidebarAuth2">
                                <ul className ="nav nav-sm flex-column">
                                {(hasPermissions('question')) && 
                                    <li className ="nav-item">
                                        <NavLink to={'/question'} className ="nav-link" > Question
                                        </NavLink>
                                       
                                    </li>
                                }
                                {(hasPermissions('prescreen')) && 
                                    <li className ="nav-item">
                                        <NavLink to={'/prescreen'} className ="nav-link" > PreScreen Template
                                        </NavLink>
                                       
                                    </li>
                                }                                   
                                   
                                </ul>
                            </div>
                        </li>
                    }
                      
                      {
                        (hasPermissions('reports')) && 


                        <li className ="nav-item">
                            <a className ="nav-link menu-link" href="#sidebarForms" data-bs-toggle="collapse" role="button" aria-expanded={(spaax==='/project-report' || spaax === '/client-report'|| spaax==='/supplier-report' || spaax === '/group-project-report'  ) ? 'true' : 'false'} aria-controls="sidebarForms">
                                <i className ="ri-pencil-ruler-2-line"></i> <span data-key="t-forms">Reports</span>
                            </a>
                            <div className={(spaax==='/project-report' || spaax === '/client-report'|| spaax==='/supplier-report' || spaax === '/group-project-report'  ) ? 'collapse menu-dropdown show' : 'collapse menu-dropdown'}  id="sidebarForms">
                                <ul className ="nav nav-sm flex-column">
                                    {(hasPermissions('project-report')) && 
                                    <li className ="nav-item">
                                        <NavLink to={'/project-report'}className ="nav-link" data-key="t-basic-elements">Project Report</NavLink>
                                    </li>
                                    }
                                    {(hasPermissions('client-report')) && 

                                    <li className ="nav-item">
                                        <NavLink to={'/client-report'} className ="nav-link" data-key="t-form-select"> Client Report </NavLink>
                                    </li>
                                    }
                                    {(hasPermissions('supplier-report')) && 

                                    <li className ="nav-item">
                                        <NavLink to={'/supplier-report'} className ="nav-link" data-key="t-checkboxs-radios">Supplier Report</NavLink>
                                    </li>
                                    }
                                    {(hasPermissions('group-report')) && 

                                    <li className ="nav-item">
                                        <NavLink to={'/group-project-report'} className ="nav-link" data-key="t-pickers"> Group Report </NavLink>
                                    </li>
                                    }
                                    {(hasPermissions('statistics-report')) && 

                                    <li className ="nav-item">
                                        <NavLink to={'/statistics-report'} className ="nav-link" data-key="t-pickers"> Statistics Report </NavLink>
                                    </li>
                                    }
                                    {(hasPermissions('rd-report')) && 
                                
                                    <li className ="nav-item">
                                        <NavLink to={'/rd-report'} className ="nav-link" data-key="t-pickers"> RD Report </NavLink>
                                    </li>
                                    }
                                    {(hasPermissions('project-activity-report')) && 

                                        <li className ="nav-item">
                                            <NavLink to={'/project-activity-report'} className ="nav-link" data-key="t-pickers"> Project Activity Report </NavLink>
                                        </li>
                                    }
                                
                                </ul>
                            </div>
                        </li>
                        }

                    {
                        (hasPermissions('support')) && 

                        <li className ="nav-item">
                            <a className ="nav-link menu-link" href="#sidebarTables" data-bs-toggle="collapse" role="button" aria-expanded={(spaax==='/ip-tracker' || spaax === '/reconciliation'|| spaax==='/blocked-ip' || spaax === '/redirect-status'  ) ? 'true' : 'false'} aria-controls="sidebarTables">
                                <i className ="ri-stack-line"></i> <span data-key="t-tables">Support</span>
                            </a>
                            <div className={(spaax==='/ip-tracker' || spaax === '/reconciliation'|| spaax==='/blocked-ip' || spaax === '/redirect-status' ) ? 'collapse menu-dropdown show' : 'collapse menu-dropdown'} id="sidebarTables">
                                <ul className ="nav nav-sm flex-column">
                                {(hasPermissions('ip-tracker')) && 

                                    <li className ="nav-item">
                                        <NavLink to={'/ip-tracker'} className ="nav-link" data-key="t-basic-tables">IP Tracker</NavLink>
                                    </li>
                                }
                                    {(hasPermissions('reconciliation')) && 
                                    <li className ="nav-item">
                                        <NavLink to={'/reconciliation'} className ="nav-link" data-key="t-grid-js">Reconciliation</NavLink>
                                    </li>
                                    }
                                    {(hasPermissions('blocked-ips')) && 
                                    <li className ="nav-item">
                                        <NavLink to={'/blocked-ip'} className ="nav-link" data-key="t-list-js">Blocked IPs</NavLink>
                                    </li>
                                    }
                                    {(hasPermissions('redirect-status')) && 
                                    <li className ="nav-item">
                                        <NavLink to={'/redirect-status '} className ="nav-link" data-key="t-datatables">Redirect Status</NavLink>
                                    </li>
                                    }
                                </ul>
                            </div>
                        </li>
                        }
                        {/* <li className="nav-item">
                            <a className="nav-link menu-link" href="#sidebarAdvanceUI" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="sidebarAdvanceUI">
                                <i className="ri-share-line"></i> <span data-key="t-advance-ui">Settings</span>
                            </a>
                            <div className="collapse menu-dropdown" id="sidebarAdvanceUI">
                                <ul className="nav nav-sm flex-column">
                                <li className ="nav-item">
                                        <NavLink to={'/'} className ="nav-link" data-key="t-datatables">Country</NavLink>
                                    </li>
                                    <li className ="nav-item">
                                        <NavLink to={'/ '} className ="nav-link" data-key="t-datatables">Currency</NavLink>
                                    </li>
                                    <li className ="nav-item">
                                        <NavLink to={'/'} className ="nav-link" data-key="t-datatables">Project Category</NavLink>
                                    </li>
                                    <li className ="nav-item">
                                        <NavLink to={'/ '} className ="nav-link" data-key="t-datatables">Project Filter</NavLink>
                                    </li>
                                    <li className ="nav-item">
                                        <NavLink to={'/ '} className ="nav-link" data-key="t-datatables">Security Filter</NavLink>
                                    </li>
                                    <li className ="nav-item">
                                        <NavLink to={'/ '} className ="nav-link" data-key="t-datatables">Device Filter</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </li> */}
                        {/* <li className="nav-item">
                            <a className="nav-link menu-link" href="#sidebarMultilevel" data-bs-toggle="collapse" role="button" aria-expanded={(spaax==='/users'  ) ? 'true' : 'false'} aria-controls="sidebarMultilevel">
                                <i className="mdi mdi-account-sync"></i> <span data-key="t-multi-level">Admin</span>
                            </a>
                            <div className={( spaax==='/users' ) ? 'collapse menu-dropdown show' : 'collapse menu-dropdown'} id="sidebarMultilevel">
                                <ul className="nav nav-sm flex-column">
                                    <li className="nav-item">
                                        <NavLink to={'/users '} className="nav-link" data-key="t-level-1.1"> Users</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to={'/'} className="nav-link" data-key="t-level-1.1"> Role </NavLink>
                                    </li>
                                
                                </ul>
                            </div>
                        </li> */}

                    {(hasPermissions('access-manager')) && 

                            // (rolePermissions.find((element) => element.slug == 'user-management'))?( 
                            <li className ="nav-item">
                                <a className ="nav-link menu-link" href="#accessManager" data-bs-toggle="collapse" role="button" aria-expanded={(spaax==='/roles' || spaax==='/modules' || spaax==='/users1') ? 'true' : 'false'} aria-controls="sidebarAuth2">
                                    <i className ="ri-user-fill"></i> <span data-key="t-authentication">Access Manager</span>
                                </a>
                                <div className={(spaax==='/roles' || spaax==='/modules' || spaax==='/users1') ? 'collapse menu-dropdown show' : 'collapse menu-dropdown'} id="accessManager">
                                    <ul className ="nav nav-sm flex-column">
                                    {(hasPermissions('roles')) && 
                                        <li className ="nav-item">
                                            <NavLink to={'/roles'} className ="nav-link"> Roles </NavLink>
                                        </li>
                                    }
                                    {(hasPermissions('modules')) && 
                                        <li className ="nav-item">
                                            <NavLink to={'/modules'} className ="nav-link"> Modules </NavLink>
                                        </li>
                                    }
                                    {(hasPermissions('users')) && 
                                        <li className ="nav-item">
                                            <NavLink to={'/users1'} className ="nav-link"> Users </NavLink>
                                        </li>
                                    }   
                                    </ul>
                                </div>
                            </li>
                            // ):(<></>)

                                                        
                        }
                      
                     
                       

                    </ul>
                
                {/* <!-- Sidebar --> */}
            </div>
            </aside>
            <div className ="sidebar-background"></div>
        </div>
        </div>
  )
}

export default Sidebar