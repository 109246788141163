import { get } from "../ApiService";

export const analyticsSection1 = async() => {
    let res = await get('/dashboard/get-analytics/section-1/?source=stage')
    return res;
}
export const analyticsSection2 = async() => {
    let res = await get('/dashboard/get-analytics/section-2/?source=stage')
    return res;
}
export const analyticsUserByDevice = async() => {
    let res = await get('/dashboard/get-analytics/users-by-device/?source=stage')
    return res;
}
export const analyticsTopProjects = async() => {
    let res = await get('/dashboard/get-analytics/top-projects/?source=stage')
    return res;
}
export const analyticsTopSupplier = async() => {
    let res = await get('/dashboard/get-analytics/top-suppliers/?source=stage')
    return res;
}