import React from 'react'
import { deleteAPI, postAPI, putAPI, get } from "../ApiService";
import Swal from "sweetalert2";
export const ProjecGrouptList = async({page=0, recPerPage=100, searchKeyword, filter}) => {
    let data = {
        page: page,
        recPerPage: recPerPage,
        q: searchKeyword,
        filters:filter
    }
    let res = await postAPI('/project/list', data)
    return res;
}

export const ProjectCreateGroup = async({payload}) => {
    let res = await postAPI('/project/group/save',payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const ProjecGroupDetail = async({id}) => {
    let res = await get('/project/group/details/'+id)
    return res;
}

export const ProjecGroupChildDetail = async({id}) => {
    let res = await get('/project/group/child/details/'+id)
    return res;
}

export const validateFormDataGroupUpdate = (formData) => {
    const{
        fullName,
        description, 
    } = formData;
    const newErrors = {};
    if(!fullName || fullName === '') newErrors.fullName = 'Please enter this field';
    if(!description || description === '') newErrors.description = 'Please enter this field';
    return newErrors
}

export const ProjectGroupUpdate = async ({id, payload}) => {
    let res = await putAPI('/project/group/update/'+id.toString(),payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const ProjectCreateGroupChild = async({payload}) => {
    let res = await postAPI('/project/group/child/add',payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const ProjectUpdateGroupChild = async({id, payload}) => {
    let res = await putAPI('/project/group/child/update/'+id,payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const ProjectUpdateStatusGroupChild = async({id, status}) => {
    let res = await putAPI('/project/group/child/update-status/'+id+'/'+status)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const ProjectGroupDelete = async({payload}) => {
    return Swal.fire({
        text: 'Are you sure you want to delete?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        if (result.value) {
            let res = await deleteAPI('/client/delete/'+payload?.clientID)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
      })
}

export const ProjectSupplierMappedGroupChild = async({payload, projectDetailID}) => {
    let res = await postAPI('/project/group/'+projectDetailID+'/add-supplier', payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const ProjectSupplierListGroupChild = async({projectDetailID}) => {
    let res = await postAPI('/project/group/'+projectDetailID+'/supplier-list')
    return res;
    // return Swal.fire({
    //     text:res.message,
    //     timer: 1500,
    // }).then(() => {
    //     return res;
    // })
}

export const ProjectMappedSupperDetail = async({supplierId, projecctDetailId}) => {
    let res = await get('/project/group/'+projecctDetailId+'/supplier-details/'+supplierId)
    return res;
}

export const ProjectSupplierMappedGroupUpdate = async({payload, projectDetailID, supplierDetailId}) => {
    let res = await putAPI('/project/group/'+projectDetailID+'/update-supplier/'+supplierDetailId, payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const ProjectGroupMappedSupperDelete = async({supplierId, projecctDetailId}) => {
    // let res = await deleteAPI('/project/group/'+projecctDetailId+'/delete-supplier/'+supplierId)
    // return Swal.fire({
    //     text:res.message,
    //     timer: 1500,
    // }).then(() => {
    //     return res;
    // })

    let message = 'Are you sure you want to delete?'

    return Swal.fire({
        text: message,
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        if (result.value) {
            let res = await deleteAPI('/project/group/'+projecctDetailId+'/delete-supplier/'+supplierId)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
      })
}

export const ProjectMappedSupplierSettingUpdate = async({supplierId, projectDetailId, payload}) => {
    let message = 'Are you sure you want to update?'
    return Swal.fire({
        text: message,
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        if (result.value) {
            let res = await putAPI('/project/group/'+projectDetailId+'/update-supplier-settings/'+supplierId, payload)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
      })
}

export const validateGroupFormData = (formData) => {
    const{
        clientID,
        fullName,
        // description,
    } = formData;
    const newErrors = {};
    if(!clientID || clientID === '') newErrors.clientID = 'Please enter this field';
    if(!fullName || fullName === '') newErrors.fullName = 'Please enter this field';
    // if(!description || description === '') newErrors.description = 'Please enter this field';
    return newErrors
}

export const validateGroupChildFormData = (formData) => {
    const{
        fullName,
        managerID,
        countryID, 
        languageID,
        categoryID,
        // description, 
        loi,
        ir,
        sampleSize,
        respondentClickQuota,
        currencyID,
        projectCPI,
        supplierCPI,
        startDate,
        endDate
    } = formData;
    const newErrors = {};
    if(!fullName || fullName === '') newErrors.fullName = 'Please enter this field';
    if(!managerID || managerID === '') newErrors.managerID = 'Please select project manager';
    if(!countryID || countryID === 'Select Country') newErrors.countryID = 'Please select country';
    if(!languageID || languageID === 'Select Language') newErrors.languageID = 'Please select language';
    if(!categoryID || categoryID === 'Select Language') newErrors.categoryID = 'Please select category';
    // if(!description || description === '') newErrors.description = 'Please enter this field';
    if(!loi || loi === '') newErrors.loi = 'Please enter this LOI';
    if(!ir || ir === '') newErrors.ir = 'Please enter this IR';
    if(!sampleSize || sampleSize === '') newErrors.sampleSize = 'Please enter sample size';
    if(!respondentClickQuota || respondentClickQuota === '') newErrors.respondentClickQuota = 'Please enter respondent click quota';
    if(!currencyID || currencyID === 'Select Language') newErrors.currencyID = 'Please select currency';
    if(!projectCPI || projectCPI === '') newErrors.projectCPI = 'Please enter this field';
    if(!supplierCPI || supplierCPI === '') newErrors.supplierCPI = 'Please enter this field';
    if(!startDate || startDate === '') newErrors.startDate = 'Please enter this field';
    if(!endDate || endDate === '') newErrors.endDate = 'Please enter this field';
    return newErrors
}

export const getSurveyLink = async({id}) => {
    let res = await get('/project/group/survey-link/'+id)
    return res;
}
export const saveSurveyLink = async({id, payload}) => {
    let res = await postAPI('/project/group/survey-link/add/'+id, payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const getGroupProjectReport = async({id}) => {
    return Swal.fire({
        text:'Loading...',
        timer: 1500,
        showConfirmButton: false
    }).then(async () => {
        let res = await get('/project/group/download-report/'+id)
        if(res.status === 1){
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
    })
}

export const getGroupChildProjectSecurity = async({projectDetailID, securityType}) => {
    let res = await get('/project/group/security/'+projectDetailID+'/'+securityType)
    return res;
}

export const updateGroupChildProjectSecurity = async({projectDetailID, securityType, payload}) => {
    let res = await postAPI('/project/group/security/update/'+projectDetailID+'/'+securityType, payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}


export const ProjectCreateGroupRecontact = async({payload}) => {
    let res = await postAPI('/project/recontact/save',payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}