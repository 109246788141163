import React, { useEffect, useState } from "react";
import { format } from 'date-fns';
import moment from 'moment'
import { masterData } from "../../../Api/Master";
import PreScreenChooseTemplate from "../PreScreen/chooseTempate";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { CCollapse, CButton, CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter } from '@coreui/react'
import { get, postAPI } from "../../../ApiService";
import PreScreenMessage from "../PreScreen/message";
import PreScreenTempQuestion from "../PreScreen/addTempQuestion";
import PreScreenViewTempQuestion from "../PreScreen/viewTempQuestion";
import PreScreenLinkQuestion from "../PreScreen/linkQuestion";
import PreScreenMappedQueView from "../PreScreen/mappedQueView";
import Swal from "sweetalert2";

function TabGroupChildPreScreen(props){
    const [questions, setQuestions] = useState([])
    const [projectMappedQues, setProjectMappedQues] = useState()
    const [priority, setPriority] = useState()
    const [visiblePreScreenMessageModal, setVisiblePreScreenMessageModal] = useState(false)
    const [visibleAddTempQueModal, setVisibleAddTempQueModal] = useState(false)
    const [visibleViewTempQueModal, setVisibleViewTempQueModal] = useState(false)
    const [visibleLinkQuestion, setVisibleLinkQuestion] = useState(false)
    const [visibleLinkQuestionOption, setVisibleLinkQuestionOption] = useState(false)
    const [preScreenerQuestionID, setPreScreenerQuestionID] = useState()
    const [searchQuesOpt, setSearchQueOpt] = useState();
    const [searchQueDetail, setSearchQuestionDetail] = useState();
    const [mappedQueDetail, setMappedQueDetail] = useState()

    useEffect(() => {
        getMappedQue()
    },[])
    const getMappedQue = async () => {
        let payload = {
            projectDetailID: props?.detail?.projectDetailID,
            page: 0,
            recPerPage: 100,
            q: ""
        }
        let res = await postAPI('/project/prescreen/get-mapped-questions', payload)
        if (res.status === 1) {
            setProjectMappedQues(res?.payload)
        }
    }
    const searchQuestion = async (e) => {
        let keyword = e.target.value
        // console.log('keyword', keyword)
        if (keyword.length > 2) {
            let payload = {
                q: keyword,
                languageID: props?.detail?.languageID
            }
            let res = await get('/project/prescreen/search-question/?q='+payload?.q+'&languageID='+payload?.languageID)
            if (res.status === 1) {
                setQuestions(res.payload)
                let que = [];
                res.payload.forEach(ele => {
                    que.push({ title: ele.title, id: ele.ID })
                });
                setSearchQueOpt(que);
            }
        }
    }

    const handleSearchQue = async(v) => {
        if(v === null){
            setVisibleLinkQuestion(false)
            setSearchQuestionDetail()
            setQuestions([])
        }else{
            setVisibleLinkQuestion(true)
            setPreScreenerQuestionID()
            setSearchQuestionDetail(v)
        }
        
    }

    const handleAddTempQueModal = async () => {
        setPreScreenerQuestionID()
        setVisibleAddTempQueModal(!visibleAddTempQueModal)
        setSearchQuestionDetail()
        setVisibleLinkQuestion(false)
    }

    const handleViewTempQueModal = async () => {
        setVisibleViewTempQueModal(!visibleViewTempQueModal)
        setSearchQuestionDetail()
        setVisibleLinkQuestion(false)
    }

    const handlePreScreenModal = async () => {
        setVisiblePreScreenMessageModal(!visiblePreScreenMessageModal)
        setSearchQuestionDetail()
        setVisibleLinkQuestion(false)
    }

    const handleLinkQuestion = async () => {
        setVisibleLinkQuestion(!visibleLinkQuestion)
        setSearchQuestionDetail()
        setVisibleLinkQuestion(false)
    }

    const handleMappedViewOption = async () => {
        setVisibleLinkQuestionOption(!visibleLinkQuestionOption)
        setSearchQuestionDetail()
        setVisibleLinkQuestion(false)
    }

    const prescreenAction =(preScreenerQuestionID, action) => {
        // console.log('action', action)
        setPreScreenerQuestionID(preScreenerQuestionID)
        setMappedQueDetail()
        setSearchQuestionDetail()
        if(action === 'editTempQuestion'){
            setVisibleAddTempQueModal(!visibleAddTempQueModal)
        }
        if(action === 'linkQuestion'){
            setVisibleLinkQuestion(!visibleLinkQuestion)
        }
    }

    const viewMappedQue = async(mappedQue) => {
        setPreScreenerQuestionID(mappedQue?.ID)
        setVisibleLinkQuestionOption(!visibleLinkQuestionOption)
        setMappedQueDetail(mappedQue)
        setSearchQuestionDetail()
        setVisibleLinkQuestion(false)
    }

    const editMappedQue = async (mappedQue) => {
        setPreScreenerQuestionID(mappedQue?.ID)
        setVisibleLinkQuestion(!visibleLinkQuestion)
        setSearchQuestionDetail()
        setMappedQueDetail(mappedQue)
        setSearchQuestionDetail()
    }

    const mappedQueDragEnter = (e, pos) => {
        setPriority(pos)
    }

    const mappedQueDragEnd = async (e, pos, preScreenQuestion) => {

        projectMappedQues?.splice(pos, 1);
        projectMappedQues.splice(priority, 0, preScreenQuestion)
        let ques = [];
        projectMappedQues?.map((q,i) => {
            ques.push({priority: i+1, preScreenQuestionID:q?.ID})
        })
        let payload = {
            projectDetailID: props?.detail?.projectDetailID,
            questions: ques
        }
        let res = await postAPI('/project/prescreen/update-question-priority', payload)
        if(res?.status === 1){
            getMappedQue()
        }
    }

    const deleteMapedQue = async(id) => {
        
        Swal.fire({
            text: 'Are you sure you want to delete?',
            confirmButtonText: 'Yes',
            showCancelButton: true,
          }).then(async (result) => {
            if (result.value) {
                let payload = {
                    projectDetailID: props?.detail?.projectDetailID,
                    preScreenerQuestionID: id
                }
                let res = await postAPI('/project/precsreen/delete-mapped-question/', payload)
                if(res?.status === 1){
                    getMappedQue()
                }
            }
          })
    }
    
    return (<>
        <div className="card-body">
            <div className="row">
                <div className='row justify-content-between'>
                    <div className="col-md-4">
                        <div className="mb-">
                            <label htmlFor="ForminputState" className="form-label mb-0">Language : {props?.detail?.languageName}</label>

                        </div>
                    </div>
                    <div className="col-md-4 ">
                        <PreScreenChooseTemplate detail={props?.detail} mappedQue={getMappedQue} />
                    </div>

                </div>

                <div className="col-md-5">
                    <div className="mt-3">
                        <Autocomplete
                            freeSolo
                            id="Search"
                            size="small"
                            disableClearable={false}
                            disabled={visibleLinkQuestion === true ? true : false}
                            onKeyDown={(e) => searchQuestion(e)}
                            options={questions}
                            onChange={(e,v) => handleSearchQue(v)}
                            getOptionLabel={(questions)=>questions?.title+' : '+questions?.question || ""}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Search Question"
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                    }}
                                    value={params?.ID}
                                // onChange={(e) => test(e)}
                                />
                            )}
                            style={{ width: "425px" }}
                        />
                    </div>
                </div>

                <div className="col-md-7 col-lg-7">
                    <div className="mt-2_btn">

                        <CButton className="btn btn-primary mx-2" onClick={() => handleAddTempQueModal()}>Add Temp Question</CButton>

                        <CButton className="btn btn-primary mx-2" onClick={() => handleViewTempQueModal()}>View Temp Question</CButton>

                        <CButton className="btn btn-primary mx-2" onClick={() => handlePreScreenModal()}>Prescreen Message</CButton>

                    </div>
                </div>
                {/* <!--end col--> */}
                {visibleLinkQuestion ? (<>
                    <PreScreenLinkQuestion 
                        detail={props?.detail} 
                        visible={handleLinkQuestion} 
                        preScreenerQuestionID={preScreenerQuestionID} 
                        queBySearch={searchQueDetail} 
                        mappedQue={getMappedQue}
                        mappedQuestionDetail={mappedQueDetail} />
                </>) : (<></>)}
                

                <div className='table-responsive'>
                    <table className="table table-striped table-hover table_client mt-3">
                        <thead>
                            <tr className="bg-primary" style={{ color: "white" }}>
                                <th scope="col" style={{ textAlign: "left" }}>Id</th>
                                <th scope="col" style={{ textAlign: "left" }}>Title</th>
                                <th scope="col" style={{ textAlign: "left" }}>Question</th>
                                <th scope="col" style={{ textAlign: "left" }}>Control</th>
                                <th scope="col" style={{ textAlign: "left" }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {projectMappedQues?.map((q, i) => {
                                return (<>
                                <tr>
                                    <td style={{ textAlign:"left" }}>{i+1}</td>
                                    <td style={{ textAlign:"left" }}>{q?.title}</td>
                                    <td style={{ textAlign:"left" }}>{q?.question}</td>
                                    <td style={{ textAlign:"left" }}>{q?.controlTypeName}</td>
                                    <td style={{ textAlign:"left" }}>
                                        <span className="p-1" 
                                            // onDragStart={(e) => mappedQueDragStart(e,i)}
                                            onDragEnter={(e) => mappedQueDragEnter(e,i)}
                                            onDragEnd={(e)=>mappedQueDragEnd(e,i, q)}
                                            key={i}
                                                draggable
                                            >
                                                <i className=" ri-drag-move-2-line pointerC"></i>
                                        </span>
                                        {q?.controlTypeSlug === 'input-text-box' ? (<>
                                            <span className="p-1"><i className="ri-eye-fill  pointerC" style={{color:"#ccc", cursor:"default"}}></i></span>
                                            <span className="p-1"><i className="ri-edit-2-line pointerC" style={{color:"#ccc", cursor:"default"}}></i></span>
                                            
                                        </>) : (<>
                                            <span className="p-1"><i className="ri-eye-fill  pointerC" onClick={() => viewMappedQue(q)} ></i></span>
                                            <span className="p-1"><i className="ri-edit-2-line pointerC" onClick={() => editMappedQue(q)} ></i></span>
                                            
                                        </>)}
                                        
                                        <span className="p-1"><i className="ri-delete-bin-2-line del pointerC" onClick={() => deleteMapedQue(q?.ID)}></i></span>
                                    </td>
                                </tr>
                                </>)
                            })}

                        </tbody>
                    </table>
                </div>
                {/* <!--end col--> */}
            </div>
            {/* <!--end row--> */}
        </div>

        <CModal size='lg' visible={visibleAddTempQueModal} onClose={() => setVisibleAddTempQueModal(false)}>
            <PreScreenTempQuestion detail={props?.detail} visible={handleAddTempQueModal} preScreenerQuestionID={preScreenerQuestionID} />
        </CModal>

        <CModal size='lg' visible={visibleViewTempQueModal} onClose={() => setVisibleViewTempQueModal(false)}>
            <PreScreenViewTempQuestion detail={props?.detail} visible={handleViewTempQueModal} action={prescreenAction} />
        </CModal>


        <CModal size='lg' visible={visiblePreScreenMessageModal} onClose={() => setVisiblePreScreenMessageModal(false)}>
            <PreScreenMessage detail={props?.detail} visible={handlePreScreenModal}  />
        </CModal>

        <CModal size='lg' visible={visibleLinkQuestionOption} onClose={() => setVisibleLinkQuestionOption(false)}>
            <PreScreenMappedQueView detail={props?.detail} visible={handleMappedViewOption} preScreenerQuestionID={preScreenerQuestionID}  />
        </CModal>
    </>)
}
export default TabGroupChildPreScreen