import React, { useState, useEffect } from 'react'
import { masterData, ClientList } from '../../Api/Master';
import { ProjectCreateGroup, validateGroupChildFormData, validateGroupFormData } from '../../Api/ProjectGroup';
import Footer from '../Layout/Footer'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import { useNavigate } from "react-router-dom";
import { format } from 'date-fns';
import { userList } from '../../Api/User'

function Group_project() {
    useEffect(() => {
        document.title = "Create Group Project - Mindforce"
    },);
    const defaultStartDate = Math.floor(new Date().getTime()/1000);
    const defaultEndDate = Math.floor(new Date(new Date().setDate(new Date().getDate() + 30)).getTime()/1000);

    const navigate = useNavigate();
    const [clients, setClients] = useState();
    const [projectManagers, setProjectManager] = useState(); 
    const [countries, setCountries] = useState();
    const [languages, setLanguages] = useState();
    const [projectCategories, setProjectCategories] = useState();
    const [currencies, setCurrencies] = useState();
    const [uniqueIP, setUniqueIp] = useState();
    const [showUniqueIp, setShowUniqueIp] = useState(false)
    const [speeder, setSpeeder] = useState();
    const [showSpeeder, setShowSpeeder] = useState(false)
    const [uniqueIPError, setUniqueIPError] = useState()
    const [speederError, setSpeederError] = useState()
    const [projectFilters, setProjectFilters] = useState();
    const [selectedProjectFileter, setSelectedProjectFilter] = useState([])
    const [securityFilters, setSecurityFilters] = useState();
    const [selectedSecurityFilter, setSelectedSecurityFilter] = useState([])
    const [deviceFilters, setDeviceFilters] = useState();
    const [selectedDeviceFilter, setSelectedDeviceFilter] = useState([])
    const [deviceFilterError, setDeviceFilterError] = useState()

    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({})
    const [childForm, setChildForm] =useState({startDate:defaultStartDate, endDate:defaultEndDate})
    const [errorsChild, setErrorsChild] = useState({})

    useEffect(() => {
        getClients()
        getCountries()
        // getLanguages()
        getProjectCategories()
        getCurrencies()
        getProjectFilters()
        getSecurityFilters()
        getDeviceFilter()
        getProjectManagers()
    },[])

    useEffect(() => {
        getLanguages()
    },[childForm?.countryID])

    const getClients = async() => {
        let res = await ClientList({page:0, searchKeyword:''})
        if(res.status === 1){
            setClients(res.payload)
        }
    }

    const getProjectManagers = async() => {
        let res = await userList({page:0, searchKeyword:'',role:"project-manager"});
        if(res.status === 1){
            setProjectManager(res.payload)
        }        
    }

    const getCountries = async() => {
        let res = await masterData({moduleName:'country'})
        if(res.status === 1){
            setCountries(res.payload)
        }
    }

    const getLanguages = async() => {
        let res = await masterData({moduleName:'language',countryID:childForm?.countryID})
        if(res.status === 1){
            setLanguages(res.payload)
        }
    }

    const getProjectCategories = async() => {
        let res = await masterData({moduleName:'product-category'})
        if(res.status === 1){
            setProjectCategories(res.payload)
        }
    }

    const getCurrencies = async() => {
        let res = await masterData({moduleName:'currency'})
        if(res.status === 1){
            setCurrencies(res.payload)
        }
    }

    const getProjectFilters = async() => {
        let res = await masterData({moduleName:'project-filters'})
        if(res.status === 1){
            setProjectFilters(res.payload)
        }
    }

    const getSecurityFilters = async() => {
        let res = await masterData({moduleName:'security-filters'})
        if(res.status === 1){
            setSecurityFilters(res.payload)
        }
    }

    const getDeviceFilter = async() => {
        let res = await masterData({moduleName:'device-filters'})
        if(res.status === 1){
            setDeviceFilters(res.payload)
            let data = []
            res?.payload?.forEach(ele => {
                data.push(ele?.id.toString());
            });
            setSelectedDeviceFilter(data)
        }
    }

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        })
        if (!!errors[field])
            setErrors({
                ...errors,
            [field]: null
        })
    }
    const setChildField = (field, value) => {
        setChildForm({
            ...childForm,
            [field]: value
        })
        if (!!errorsChild[field])
        setErrorsChild({
                ...errorsChild,
            [field]: null
        })
    }

    const handleDates = (e, type) => {
        let date = Math.floor(new Date(e.target.value?.toString()).getTime()/1000)
        switch (type) {
            case 'startDate':
                form.childProject.startDate = date
                break;
            case 'endDate':
                form.childProject.endDate = date
                break;
        
            default:
                break;
        }
    }

    const handleProjectFilter = (e) => {
        const {value, checked} = e.target;
        const slug = e.target.dataset?.key
        if (checked) {
            if(slug === 'unique-ip'){
                setUniqueIp(1)
                setShowUniqueIp(true)
            }
            if(slug === 'speeder'){
                setShowSpeeder(true)
            }
            if(slug === 'captcha'){
                // document.getElementById('projectFilter'+418).checked = true;
                document.getElementById('projectFilter'+418).click();
            }
            setSelectedProjectFilter([...selectedProjectFileter, value]);
        } else {
            if(slug === 'unique-ip'){
                setUniqueIp(null)
                setShowUniqueIp(false)
            }
            if(slug === 'speeder'){
                setShowSpeeder(false)
            }
            if(slug === 'captcha'){
                // document.getElementById('projectFilter'+418).checked = false;
                document.getElementById('projectFilter'+418).click();
            }
            setSelectedProjectFilter(selectedProjectFileter.filter((s) => s !== value));
        }
    }

    const handleSecurityFilter = (e) => {
        const {value, checked} = e.target;
        if (checked) {
            setSelectedSecurityFilter([...selectedSecurityFilter, value]);
        } else {
            setSelectedSecurityFilter(selectedSecurityFilter.filter((s) => s !== value));
        }
    }

    const handleDeviceFilter = (e) => {
        const {value, checked} = e.target;
        if (checked) {
            setSelectedDeviceFilter([...selectedDeviceFilter, value]);
        } else {
            setSelectedDeviceFilter(selectedDeviceFilter.filter((s) => s !== value));
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors()
        setDeviceFilterError()
        setSpeederError()
        setUniqueIPError()
        let proFilter = [];
        let value = null;
        let uniqueIpErrorCount = 0;
        let speederErrorCount = 0;
        projectFilters?.forEach(p => {
            let status = 1
            if(selectedProjectFileter.indexOf(p.id.toString()) === -1){
                status = 0;
            }
            if(p.id === 420 && status===1){
                if(uniqueIP){
                    value = uniqueIP
                }else{
                    uniqueIpErrorCount = uniqueIpErrorCount + 1;
                    value = null
                }
                
            }
            else if(p.id === 421 && status===1){
                if(speeder){
                    value=speeder
                }else{
                    speederErrorCount = speederErrorCount + 1;
                    setSpeederError("Required")
                    value = null
                }
                // value = speeder
                
            }else{
                value = null;
            }
            proFilter.push({id:p.id, status:status, value:value})
        });

        let secFilter = [];
        securityFilters?.forEach(s => {
            let status = 1
            if(selectedSecurityFilter.indexOf(s.id.toString()) === -1){
                status = 0;
            }
            secFilter.push({id:s.id, status:status, value:null})
        });

        let devFilter = [];
        let isDeviceFilterExist = deviceFilters?.length;
        deviceFilters?.forEach(d => {
            let status = 1
            
            let sel = document.getElementById('deviceFilter'+d?.id)
            if(!sel.checked){
                status = 0;
                isDeviceFilterExist = isDeviceFilterExist-1;
            }
            devFilter.push({id:d.id, status:status, value:null})
        });
        
        const formErrors = validateGroupFormData(form)
        const childFormErrors = validateGroupChildFormData(childForm)
        if(Object.keys(formErrors).length > 0){
            setErrors(formErrors)
        }else if(Object.keys(childFormErrors).length > 0){
            setErrorsChild(childFormErrors)
        }else{ 
            if(isDeviceFilterExist < 1){
                setDeviceFilterError("Required")
                return false;
            }
            if(uniqueIpErrorCount > 0){
                setUniqueIPError("Required")
                return false;
            }
            if(speederErrorCount > 0){
                setSpeederError("Required")
                return false;
            }

            childForm.projectFilters = proFilter
            childForm.securityFilters = secFilter
            childForm.deviceFilters = devFilter

            form.childProject = [childForm];
            // form.childProject.projectFilters = proFilter;
            // form.childProject.securityFilters = secFilter;
            // form.childProject.deviceFilters = devFilter;

            let res = await ProjectCreateGroup({payload:form});
            if(res.status === 1){
                //window.location.reload();
                navigate("/group-project-child-detail?id=" + res?.payload.projectDetailID);
            }else{
                setErrors({...errors,['submit']:res.message})
            }
        }
        
    }
    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            <div className="row">
                                <div className="col-12 col-lg-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Create Group Project</h4>

                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a >Project</a></li>
                                                <li className="breadcrumb-item active">Create Group Project</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}

                            <div className="row">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">

                                        {/* <!-- end card header --> */}

                                        <div className="card-body">
                                            <div className="">
                                                <form >
                                                    <div className="row">
                                                    
                                                    <div className="col-md-3 offset-md-10 " style={{textAlign:"right"}} >
                                                            <div className="form-check mb-3 " >
                                                                <input className="form-check-input" type="checkbox" id="formCheck1" />
                                                                <label className="form-check-label"htmlFor="formCheck1" >
                                                                    Dynamic Thanks 
                                                                 </label>
                                                            </div>
                                                        
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="clientId" className="form-label mb-0">Client <span className="required_field">*{errors?.clientID}</span></label>
                                                                <select id="clientId" className="form-select" data-choices data-choices-sorting="true" onChange={(e) => setField('clientID', e.target.value)}>
                                                                    <option value={""} disabled selected>Select Client</option>
                                                                    {clients?.map((row) => {
                                                                        return (<option value={row.clientID}>{row.clientCode + ' : ' +row.clientName}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="ProjectName" className="form-label mb-0">Project Name <span className="required_field">*{errors?.fullName}</span></label>
                                                                <input type="text" className="form-control" placeholder="Enter project name" id="ProjectName" onChange={(e) => setField('fullName', e.target.value)} />
                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                            <label htmlFor="Projectdescription" className="form-label mb-0">Project Description <span className="required_field">{errors?.description}</span></label>
                                                                <input type="text" className="form-control" placeholder="Enter project description" id="Projectdescription" onChange={(e) => setField('description', e.target.value)} />
                                                             </div>
                                                        </div>


                                                        {/* <!--end col--> */}
                                                        <div className="form_divide"><hr ></hr></div>
                                                        <h1 className="project_filter mb-3">Child Project Details</h1>
                                                      
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="chileProjectName" className="form-label mb-0">Project Name <span className="required_field">*{errorsChild?.fullName}</span></label>
                                                                <input type="text" className="form-control" placeholder="Enter project name" id="chileProjectName" onChange={(e) => setChildField('fullName', e.target.value)} />
                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="ProjectManager" className="form-label mb-0">Project Manager <span className="required_field">*{errorsChild?.managerName}</span></label>
                                                                {/* <input type="text" className="form-control" placeholder="Enter field" id="ProjectManager" onChange={(e) => setChildField('managerName', e.target.value)} /> */}
                                                                <select id="managerID" className="form-select" data-choices data-choices-sorting="true" onChange={(e) => setChildField('managerID',e.target.value)}>
                                                                    <option value={""} disabled selected>Select Project Manager</option>
                                                                    {projectManagers?.map((manager) => {
                                                                        return (<option value={manager.ID}>{manager.firstName+" "+manager.lastName}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="ForminputState" className="form-label mb-0">Project Country <span className="required_field">*{errorsChild?.CountryID}</span></label>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" onChange={(e) => setChildField('countryID', e.target.value)}>
                                                                    <option value={""} disabled selected>Select Country</option>
                                                                    {countries?.map((row) => {
                                                                        return (<option value={row.id}>{row.name}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="ForminputState" className="form-label mb-0">Project Language <span className="required_field">*{errorsChild?.languageID}</span></label>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" onChange={(e) => setChildField('languageID', e.target.value)}  disabled={childForm?.countryID ? false : true}>
                                                                    <option value={""} disabled selected>Select Language</option>
                                                                    {languages?.map((row) => {
                                                                        return (<option value={row.id}>{row.name}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="ForminputState" className="form-label mb-0">Project Category <span className="required_field">*{errorsChild?.categoryID}</span></label>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" onChange={(e) => setChildField('categoryID', e.target.value)}>
                                                                    <option value={""} disabled selected>Select Category</option>
                                                                    {projectCategories?.map((row) => {
                                                                        return (<option value={row.id}>{row.name}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="childProjectDescription" className="form-label mb-0">Project Description <span className="required_field">{errorsChild?.description}</span></label>
                                                                <input type="text" className="form-control" placeholder="Enter project description" id="childProjectDescription" onChange={(e) => setChildField('description', e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="form_divide"><hr ></hr></div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col">
                                                            <div className="mb-3">
                                                                <label htmlFor="childLOI" className="form-label mb-0">LOI(Minute) <span className="required_field">*{errorsChild?.loi}</span></label>
                                                                <input type="text" className="form-control" placeholder="Enter " id="childLOI" onChange={(e) => setChildField('loi', e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="mb-3">
                                                                <label htmlFor="childIR" className="form-label mb-0">IR(%) <span className="required_field">*{errorsChild?.ir}</span></label>
                                                                <input type="text" className="form-control" placeholder="Enter " id="childIR" onChange={(e) => setChildField('ir', e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="mb-3">
                                                                <label htmlFor="childSampleSize" className="form-label mb-0">Sample Size <span className="required_field">*{errorsChild?.sampleSize}</span></label>
                                                                <input type="text" className="form-control" placeholder="0" id="childSampleSize" onChange={(e) => setChildField('sampleSize', e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="mb-3">
                                                                <label htmlFor="childRespondentClick" className="form-label mb-0">Respondent Click Quota <span className="required_field">*{errorsChild?.respondentClickQuota}</span></label>
                                                                <input type="text" className="form-control" placeholder="0" id="childRespondentClick" onChange={(e) => setChildField('respondentClickQuota', e.target.value)} />
                                                            </div>
                                                        </div>

                                                        {/*  */}
                                                        <div className="col">
                                                            <div className="mb-3">
                                                                <label htmlFor="childCurrency" className="form-label mb-0">Currency <span className="required_field">*{errorsChild?.currencyID}</span></label>
                                                                <select id="childCurrency" className="form-select" data-choices data-choices-sorting="true" onChange={(e) => setChildField('currencyID', e.target.value)}>
                                                                    <option value={""} disabled selected>Select </option>
                                                                    {currencies?.map((row) => {
                                                                        return (<option value={row.id}>{row.name}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-md-3">
                                                            <div className="mb-3">
                                                                <label htmlFor="childProjectCPI" className="form-label mb-0">Project CPI <span className="required_field">*{errorsChild?.projectCPI}</span></label>
                                                                <input type="text" className="form-control" placeholder="Enter " id="childProjectCPI" onChange={(e) => setChildField('projectCPI', e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="mb-3">
                                                                <label htmlFor="childSupplierCPI" className="form-label mb-0">Supplier CPI <span className="required_field">*{errorsChild?.supplierCPI}</span></label>
                                                                <input type="text" className="form-control" placeholder="0" id="childSupplierCPI" onChange={(e) => setChildField('supplierCPI', e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-3'>
                                                            <div className="mb-3">
                                                                <label htmlFor="childStartDate" className="form-label mb-0">Start Date <span className="required_field">*{errorsChild?.startDate}</span></label>
                                                                <input type="date" className="form-control" id="childStartDate" defaultValue={format(childForm?.startDate*1000, 'yyyy-MM-dd').toString()} onChange={(e) => setChildField('startDate',Math.floor(new Date(e.target.value?.toString()).getTime()/1000))} />
                                                                <input type="text" className="form-control" value={childForm?.startDate ? format(new Date(childForm?.startDate*1000),'dd MMM yyyy') : format(new Date(), 'dd MMM, yyyy')} readOnly={true} style={{position:"absolute", top:"20px", width:"190px", borderRightStyle:"none" }} />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-3'>
                                                            <div className="mb-3">
                                                                <label htmlFor="childEndDate" className="form-label mb-0">End Date <span className="required_field">*{errorsChild?.endDate}</span></label>
                                                                <input type="date" className="form-control" id="childEndDate" defaultValue={format(childForm?.endDate*1000, 'yyyy-MM-dd').toString()} onChange={(e) => setChildField('endDate', Math.floor(new Date(e.target.value?.toString()).getTime()/1000))} />
                                                                <input type="text" className="form-control" value={childForm?.endDate ? format(new Date(childForm?.endDate*1000),'dd MMM yyyy') : ""} readOnly={true} style={{position:"absolute", top:"20px", width:"190px", borderRightStyle:"none" }} />
                                                            </div>
                                                        </div>
                                                        

                                                        <div className="form_divide"><hr ></hr></div>
                                                        <h1 className="project_filter">Project Filter</h1>
                                                        {projectFilters?.map((row) => {
                                                            return (
                                                                <div className="col-md-3">
                                                                    <div className="form-check mb-3" style={{display:"flex"}}>
                                                                        <input className="form-check-input" type="checkbox" data-key={row?.slug} id={"projectFilter"+row.id} value={row.id} onClick={(e) => handleProjectFilter(e)} style={{marginRight:"4px"}} />
                                                                        <label className="form-check-label"htmlFor={"projectFilter"+row.id}>
                                                                            {row.name}
                                                                            
                                                                        </label>
                                                                        {(row.slug =="unique-ip" && showUniqueIp ? (<>
                                                                            <span className="required_field">*{uniqueIPError}</span>
                                                                            <input type="text" className="small-input" style={{height:"20px", width:"50px"}} placeholder="" id="UniqueIP" defaultValue={uniqueIP} onChange={(e) => setUniqueIp(e.target.value)}  />
                                                                        </>) : (<></>))}
                                                                          {row.slug =="speeder" && showSpeeder ? (<>
                                                                            <span className="required_field">*{speederError}</span>
                                                                            <input type="text" className="small-input" style={{height:"20px", width:"50px"}} placeholder="" id="Speeder" defaultValue={speeder} onChange={(e) => setSpeeder(e.target.value)}  /> 
                                                                          </>) : (<></>)}
                                                                        {/* {
                                                                            row.name =="Unique IP" &&
                                                                            <input type="text" className="small-input" style={{height:"20px", width:"50px"}} placeholder="" id="UniqueIP" defaultValue={uniqueIP} onChange={(e) => setUniqueIp(e.target.value)} />
                                                                        }
                                                                          {
                                                                            row.name =="Speeder" &&
                                                                            <input type="text" className="small-input" style={{height:"20px", width:"50px"}} placeholder="" id="Speeder" defaultValue={speeder} onChange={(e) => setSpeeder(e.target.value)} />
                                                                        } */}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}

                                                        <div className="form_divide"><hr ></hr></div>
                                                        <h1 className="project_filter">Security Filter</h1>
                                                        {securityFilters?.map((row) => {
                                                            return (
                                                                <div className="col-md-3">
                                                                    <div className="form-check mb-3">
                                                                    <input className="form-check-input" type="checkbox" id={"securityFilter"+row.id} value={row.id} onChange={(e) => handleSecurityFilter(e)} />
                                                                        <label className="form-check-label"htmlFor={"securityFilter"+row.id}>
                                                                            {row.name}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}

                                                        <div className="form_divide"><hr ></hr></div>
                                                        <h1 className="project_filter">Device Filter <span className="required_field">*{deviceFilterError}</span></h1>
                                                        {deviceFilters?.map((row) => {
                                                            return (
                                                                <div className="col-md-3">
                                                                    <div className="form-check mb-3">
                                                                    <input className="form-check-input" type="checkbox" id={"deviceFilter"+row.id} value={row.id} onChange={(e) => handleDeviceFilter(e)} defaultChecked={true} />
                                                                        <label className="form-check-label"htmlFor={"deviceFilters"+row.id}>
                                                                            {row.name}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                        

                                                        <div className="form_divide"><hr ></hr></div>
                                                        <div className="col-md-12">
                                                            <div className="mb-3">
                                                                <label htmlFor="exampleFormControlTextarea5" className="form-label mb-0">Notes </label>
                                                                <textarea className="form-control" id="exampleFormControlTextarea5" rows="3" onChange={(e) => setChildField('notes', e.target.value)}></textarea>
                                                                <span className="max_char">Maximum limit of characters 250</span>
                                                            </div>
                                                        </div>


                                                        <div className="col-lg-12">
                                                            <div className="text-end">
                                                                <button type="submit" className="btn btn-primary mx-2" onClick={handleSubmit}>Submit</button>
                                                                <button type="submit" className="btn btn-secondary">Cancel</button>
                                                            </div>
                                                        </div>


                                                        {/* <!--end col--> */}
                                                    </div>
                                                    {/* <!--end row--> */}
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end col --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Group_project